<template>
  <div class="row justify-content-between">
    <div v-if="loading" class="col-8 ">
      <loading :loading="loading" />
    </div>
    <div v-if="!loading" class="col-5 ">
      <div class="notification-setting-content">
        <div>
          <h3>Language</h3>
          <div class="form-group">
            <label>Preferred Language</label>
            <multi-select
              label="name"
              :placeholder="'Select Language'"
              :showLabels="false"
              :clearOnSelect="false"
              :allowEmpty="false"
              v-model="selectedLang"
              :options="langs"
            >
            </multi-select>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button @click.prevent="saveLang" class="btn btn-outline-primary">
          Save Changkkes
        </button>
      </div>
    </div>
    <div class="col-4">
      <div class="setting-info-card">
        <div class="icon">
          <v-icon :icon="['fac', 'bulb']" />
        </div>
        <h3 class="title">
          Overcome language barriers
        </h3>
        <hr class="w-75" />
        <p>
          No matter what language you choose, we will translate everything for
          the other users to create exceptional netwroking experience.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import getNotify from "@/graphql/me/profile/getNotify.graphql";
import updateNotify from "@/graphql/me/profile/updateNotify.graphql";
export default {
  name: "langTab",
  data() {
    return {
      selectedLang: {
        code: "EN",
        name: "English"
      },
      loading: true,
      langs: [
        {
          code: "EN",
          name: "English"
        }
      ]
    };
  },
  methods: {
    saveLang() {
      return this.$bvToast.ttoast(this.$t("Toaster.lang-saved"), {
        title: this.$t("Toaster.success-title"),
        variant: "success",
       autoHideDelay: 1350
      });
    }
  },
  created() {
    this.loading = false;
  }
};
</script>
